import type { PaginationResponse, Team } from '@/types'

export const useGetTeams = async () => {
  const { data, error } = await useAPIFetch(`/teams`)
  if (error.value) throw createError(error.value)
  return data.value as PaginationResponse
}

export const useGetTeamsList = async () => {
  const { data, error } = await useAPIFetch(`/teams/list`)
  if (error.value) throw createError(error.value)
  return data.value as PaginationResponse
}

export const useCreateTeam = async (team: Team) => {
  const { data, error } = await useAPIFetch(`/teams`, { method: 'POST', body: team })
  if (error.value) throw createError(error.value)
  return data.value as PaginationResponse
}

export const useUpdateTeam = async (team: Team) => {
  const { data, error } = await useAPIFetch(`/teams/${team.id}`, { method: 'PATCH', body: team })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useDeleteTeam = async (team: Team) => {
  const { data, error } = await useAPIFetch(`/teams/${team.id}`, { method: 'DELETE' })
  if (error.value) throw createError(error.value)
  return data.value
}
export const useChangeUsersTeam = async (userId: number, teamId: number) => {
  const { data, error } = await useAPIFetch(`/user/${userId}/change_team`, {
    method: 'PATCH',
    body: { new_team_id: teamId },
  })
  if (error.value) throw createError(error.value)
  return data.value
}
